import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 230 189">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" >
            
<path fill="#FEFFFE" opacity="1.000000" stroke="none" 
	d="
M1.000000,92.000000 
	C1.000000,61.682549 1.000000,31.365097 1.000000,1.023822 
	C77.610641,1.023822 154.221283,1.023822 230.915970,1.023822 
	C230.915970,63.910896 230.915970,126.821938 230.915970,189.866486 
	C154.333481,189.866486 77.666779,189.866486 1.000000,189.866486 
	C1.000000,158.237564 1.000000,126.587440 1.453988,94.457687 
	C4.060194,93.971565 6.236226,94.152634 8.355109,93.883591 
	C9.504647,93.737640 11.490572,92.855705 11.510204,92.253914 
	C11.734655,85.373466 11.652989,78.483032 11.652989,71.595261 
	C11.121199,71.599503 10.589408,71.603745 10.057617,71.607986 
	C10.057617,78.220802 10.057617,84.833611 10.057617,92.000000 
	C6.543910,92.000000 3.771955,92.000000 1.000000,92.000000 
M130.601089,157.995422 
	C131.065292,157.698578 131.529495,157.401749 132.516037,156.756714 
	C133.358215,156.176147 134.200394,155.595581 135.602402,154.912292 
	C136.070786,154.596420 136.539154,154.280533 137.549774,153.874603 
	C137.806152,153.620605 138.062531,153.366608 138.971924,153.012497 
	C140.658020,152.045242 142.344131,151.077972 144.702164,150.056015 
	C145.293243,149.764359 145.884323,149.472702 146.994690,149.239502 
	C147.330154,149.150497 147.665634,149.061493 148.707169,149.044632 
	C149.104813,148.673615 149.502441,148.302597 149.900085,147.931595 
	C149.301270,147.547684 148.702469,147.163773 147.900757,146.033951 
	C144.117081,144.659241 140.333405,143.284531 135.810211,141.641129 
	C138.918030,147.795898 134.830215,150.064880 131.212784,152.990799 
	C128.769806,153.971664 126.376945,155.110184 123.875023,155.905670 
	C113.947662,159.061996 104.018288,157.822205 93.604195,155.239212 
	C89.480659,152.421799 85.357117,149.604370 81.313606,146.549454 
	C81.255463,146.365662 81.130096,146.265274 80.984825,146.116501 
	C80.984825,146.116501 80.858421,146.056274 80.850456,145.269363 
	C80.875481,142.179596 80.900505,139.089844 81.312805,135.473938 
	C81.979027,134.355820 82.645248,133.237701 83.839806,131.879532 
	C84.368202,131.314804 84.896599,130.750092 86.048576,130.019409 
	C87.372383,129.300568 88.696198,128.581726 90.552109,127.844345 
	C90.799095,127.597855 91.046082,127.351364 92.128380,127.135551 
	C92.850395,127.801979 94.241379,128.526962 94.188980,129.125610 
	C93.754623,134.088425 96.750595,140.018845 89.081047,143.560730 
	C95.878433,143.560730 101.288376,143.560730 106.698326,143.560730 
	C106.763657,143.271759 106.828995,142.982773 106.894325,142.693802 
	C105.648918,142.113922 104.403511,141.534058 103.004089,140.039307 
	C102.789589,135.716568 102.575096,131.393829 103.284851,126.970184 
	C104.522041,126.945114 105.759232,126.920044 107.653572,127.181549 
	C110.431641,128.457108 113.209702,129.732651 115.935936,131.898544 
	C115.554703,135.903534 117.671989,140.695068 112.396782,143.522400 
	C117.764801,143.522400 123.132820,143.522400 128.500839,143.522400 
	C123.813599,141.440781 123.951378,137.636795 124.036278,133.710571 
	C124.158722,128.048096 124.167824,122.378502 124.030396,116.716675 
	C123.933800,112.737114 123.696716,108.836960 129.486282,106.567444 
	C122.737022,106.567444 117.507591,106.567444 113.509094,106.567444 
	C114.369652,109.357307 115.569893,111.831955 115.822029,114.399712 
	C116.194595,118.193886 115.893196,122.054253 115.143463,125.886635 
	C112.448540,125.213493 109.753616,124.540352 106.440018,123.408791 
	C104.619431,123.286469 102.798836,123.164154 100.628365,122.376740 
	C100.669220,121.889954 100.710068,121.403175 101.542618,120.790642 
	C101.885513,118.872902 102.115067,116.926079 102.597855,115.044235 
	C103.323624,112.215332 104.226730,109.431923 105.053719,106.631622 
	C100.920006,106.631622 95.640709,106.631622 90.361412,106.631622 
	C95.468697,108.596199 94.085968,112.726532 94.337852,116.232628 
	C94.480797,118.222374 93.100929,121.421509 97.421295,121.700729 
	C96.300209,122.202675 95.179123,122.704628 93.414764,123.132973 
	C92.610100,123.414238 91.805428,123.695503 90.343552,123.951103 
	C89.782692,124.253593 89.221832,124.556084 88.067436,124.875580 
	C87.378471,125.239754 86.689507,125.603928 85.398064,126.076965 
	C85.162918,126.397827 84.927773,126.718697 84.207840,127.129837 
	C83.801521,127.394295 83.395203,127.658752 82.395119,128.146408 
	C81.570038,129.086075 80.744957,130.025742 79.468254,131.193359 
	C79.263588,131.641724 79.058922,132.090103 78.371521,132.890717 
	C78.228722,133.254974 78.085930,133.619247 77.452835,134.354401 
	C77.247002,135.129547 77.041176,135.904694 76.305939,137.235275 
	C75.748108,139.458267 75.190277,141.681274 74.602264,144.024567 
	C68.657417,143.192078 63.244522,143.565048 60.791428,150.029022 
	C59.075993,154.549255 61.450439,157.665253 65.051109,160.736832 
	C65.700417,160.829330 66.349724,160.921829 67.871208,160.978912 
	C69.906937,160.968185 71.942657,160.957458 74.503822,161.528778 
	C77.002792,161.368134 79.501770,161.207489 82.191414,160.529099 
	C82.227509,160.348770 82.263596,160.168442 82.539078,159.749603 
	C82.389587,159.498184 82.240097,159.246780 81.789505,158.442444 
	C81.555748,158.093948 81.321983,157.745453 81.926193,156.997986 
	C82.308830,156.208847 82.691467,155.419708 83.705223,154.868774 
	C84.473320,155.332199 85.241417,155.795624 86.260658,156.621506 
	C86.480309,156.838058 86.743935,156.958008 87.689835,157.061707 
	C88.449562,157.441818 89.209282,157.821945 90.057320,158.747314 
	C90.699295,158.850616 91.341278,158.953918 92.681221,159.092926 
	C93.788933,159.444992 94.896645,159.797073 96.295113,160.662170 
	C97.202690,160.760559 98.110260,160.858948 99.819031,160.934921 
	C100.882057,161.030045 101.945076,161.125168 103.451241,161.836411 
	C108.080070,163.966339 112.578827,163.515060 117.716331,161.004578 
	C118.464783,160.968323 119.213242,160.932083 120.254807,161.372147 
	C120.850555,161.001602 121.446297,160.631058 122.675186,160.078247 
	C123.111557,160.037201 123.547920,159.996140 124.406548,160.469223 
	C126.275909,159.654587 128.145279,158.839966 130.001129,157.998520 
	C130.001129,157.998520 130.025070,158.017944 130.601089,157.995422 
M169.522537,171.017563 
	C169.340195,171.012634 169.157852,171.007690 168.663284,170.488388 
	C168.111237,170.994476 167.559174,171.500565 166.184372,172.018738 
	C164.813385,172.001511 163.442413,171.984299 161.951096,171.258865 
	C160.991562,170.853714 160.032028,170.448578 158.232986,170.000809 
	C155.392273,169.616821 152.551559,169.232819 149.643402,168.839706 
	C149.135361,171.237778 148.788834,172.873459 148.145538,175.909973 
	C147.142975,172.826111 146.594757,171.139816 146.046539,169.453506 
	C145.604218,169.230743 145.161880,169.007980 144.719559,168.785217 
	C143.880203,170.397263 143.040833,172.009308 142.178177,173.666092 
	C140.691910,172.618210 139.898712,172.283249 139.431503,171.691086 
	C137.309113,169.001068 135.462097,169.654922 135.201645,172.608704 
	C134.815338,176.989761 132.054840,176.990936 128.906509,176.125336 
	C128.924927,174.084366 128.943359,172.043411 129.390381,169.827896 
	C129.657654,169.658615 129.862228,169.432587 130.734940,169.075333 
	C131.280441,169.012253 131.825943,168.949173 132.371445,168.886108 
	C132.303726,168.459747 132.235992,168.033386 132.168274,167.607025 
	C129.994278,168.084976 127.820274,168.562927 125.242897,169.701035 
	C125.477837,172.804947 125.712784,175.908875 126.708244,179.125519 
	C127.476280,179.103546 128.244324,179.081573 129.562134,179.622314 
	C132.796066,179.362335 136.035538,178.843231 139.262405,178.912476 
	C142.755127,178.987442 146.229721,179.713913 149.726196,179.929810 
	C150.706085,179.990326 152.508194,179.407318 152.618500,178.856323 
	C153.433853,174.783264 156.498001,176.238586 158.974976,176.787354 
	C159.130966,179.417862 160.178741,179.821701 162.831528,178.008987 
	C164.221558,177.673019 165.611603,177.337051 167.022247,177.492279 
	C167.369965,177.675613 167.717667,177.858948 168.677261,177.972137 
	C169.466919,178.182785 170.248581,178.434113 171.048721,178.591568 
	C171.720016,178.723663 172.412842,178.746246 173.811707,178.960861 
	C179.754517,183.233902 177.081055,174.080185 180.438629,174.584671 
	C179.766586,173.735001 179.022247,172.793930 178.277924,171.852875 
	C179.327164,171.306259 180.376404,170.759644 182.167603,169.826477 
	C179.382919,169.988907 177.409927,170.103973 175.615997,170.208618 
	C175.081116,172.432312 174.635498,174.284897 173.630463,175.881973 
	C173.097458,175.586838 172.564468,175.291702 172.104095,174.306290 
	C173.031555,172.216614 173.034576,170.708191 169.522537,171.017563 
M42.031059,168.654907 
	C41.086529,168.751434 39.322151,168.818298 39.317432,168.948822 
	C39.200867,172.170242 39.185356,175.404861 39.428402,178.616196 
	C39.455143,178.969528 41.154388,179.196259 42.603817,179.644257 
	C42.416767,175.768417 42.265347,172.630783 42.031059,168.654907 
M79.695938,177.998184 
	C79.085030,175.277390 78.474121,172.556595 77.863213,169.835785 
	C77.322128,169.959076 76.781036,170.082352 76.239952,170.205627 
	C76.014664,173.193237 75.789383,176.180862 75.557182,179.260132 
	C73.712753,178.988235 72.482773,178.806915 71.252785,178.625610 
	C71.236435,178.850433 71.220085,179.075272 71.203735,179.300095 
	C74.597443,179.300095 77.991142,179.300095 81.446030,179.300095 
	C81.038322,178.965576 80.663361,178.657913 79.695938,177.998184 
M116.924622,177.213303 
	C117.129761,173.382645 115.287109,171.146545 111.538322,169.606430 
	C112.506294,172.902557 113.180923,175.524307 114.142593,178.036194 
	C114.254349,178.328094 115.956146,178.011246 116.924622,177.213303 
M98.807007,178.678375 
	C98.807007,175.781845 98.807007,172.885315 98.807007,169.988785 
	C95.020332,173.505692 95.353226,177.780609 98.807007,178.678375 
z"/>
<path fill="#D6D5D5" opacity="1.000000" stroke="none" 
	d="
M1.000000,92.333328 
	C3.771955,92.000000 6.543910,92.000000 10.057617,92.000000 
	C10.057617,84.833611 10.057617,78.220802 10.057617,71.607986 
	C10.589408,71.603745 11.121199,71.599503 11.652989,71.595261 
	C11.652989,78.483032 11.734655,85.373466 11.510204,92.253914 
	C11.490572,92.855705 9.504647,93.737640 8.355109,93.883591 
	C6.236226,94.152634 4.060194,93.971565 1.453988,93.989029 
	C1.000000,93.555557 1.000000,93.111107 1.000000,92.333328 
z"/>
<path fill="#66B38A" opacity="1.000000" stroke="none" 
	d="
M86.000542,125.968094 
	C86.689507,125.603928 87.378471,125.239754 88.704239,124.896957 
	C89.894287,124.604485 90.447517,124.290627 91.000755,123.976776 
	C91.805428,123.695503 92.610100,123.414238 94.015991,123.406898 
	C96.737556,123.467819 98.857903,123.254822 100.978249,123.041824 
	C102.798836,123.164154 104.619431,123.286469 106.732422,124.016586 
	C107.015350,125.381241 107.005882,126.138100 106.996414,126.894966 
	C105.759232,126.920044 104.522041,126.945114 102.602585,127.284492 
	C100.812195,132.191544 99.580070,136.795776 103.158104,140.954193 
	C104.403511,141.534058 105.648918,142.113922 106.894325,142.693802 
	C106.828995,142.982773 106.763657,143.271759 106.698326,143.560730 
	C101.288376,143.560730 95.878433,143.560730 89.081047,143.560730 
	C96.750595,140.018845 93.754623,134.088425 94.188980,129.125610 
	C94.241379,128.526962 92.850395,127.801979 91.450729,127.155006 
	C90.522049,127.403931 90.271019,127.633408 90.020004,127.862885 
	C88.696198,128.581726 87.372383,129.300568 85.367081,130.025360 
	C84.120018,129.328613 83.554451,128.625916 82.988892,127.923210 
	C83.395203,127.658752 83.801521,127.394295 84.729919,126.985855 
	C85.501518,126.550613 85.751030,126.259354 86.000542,125.968094 
z"/>
<path fill="#DBF7E8" opacity="1.000000" stroke="none" 
	d="
M158.895035,176.042221 
	C156.498001,176.238586 153.433853,174.783264 152.618500,178.856323 
	C152.508194,179.407318 150.706085,179.990326 149.726196,179.929810 
	C146.229721,179.713913 142.755127,178.987442 139.262405,178.912476 
	C136.035538,178.843231 132.796066,179.362335 129.264374,178.996567 
	C128.966705,177.913315 128.966797,177.455826 128.966888,176.998322 
	C132.054840,176.990936 134.815338,176.989761 135.201645,172.608704 
	C135.462097,169.654922 137.309113,169.001068 139.431503,171.691086 
	C139.898712,172.283249 140.691910,172.618210 142.178177,173.666092 
	C143.040833,172.009308 143.880203,170.397263 144.719559,168.785217 
	C145.161880,169.007980 145.604218,169.230743 146.046539,169.453506 
	C146.594757,171.139816 147.142975,172.826111 148.145538,175.909973 
	C148.788834,172.873459 149.135361,171.237778 149.643402,168.839706 
	C152.551559,169.232819 155.392273,169.616821 158.644119,170.449463 
	C159.001846,172.612823 158.948441,174.327515 158.895035,176.042221 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M82.299690,159.988098 
	C82.263596,160.168442 82.227509,160.348770 81.719376,160.707748 
	C78.824356,160.906509 76.401375,160.926605 73.978386,160.946716 
	C71.942657,160.957458 69.906937,160.968185 67.241516,160.778137 
	C66.059982,160.446915 65.508148,160.316467 64.956306,160.186005 
	C61.450439,157.665253 59.075993,154.549255 60.791428,150.029022 
	C63.244522,143.565048 68.657417,143.192078 74.602264,144.024567 
	C75.190277,141.681274 75.748108,139.458267 76.622017,137.405838 
	C78.572250,142.455048 74.500832,144.365280 71.597885,147.113739 
	C69.914505,147.352707 68.094841,147.110260 67.439659,147.870880 
	C65.995697,149.547256 64.710960,151.641556 64.276772,153.753525 
	C64.126381,154.485062 66.681923,155.772873 68.001495,156.806595 
	C69.202126,155.136536 70.402763,153.466476 71.578102,151.831604 
	C74.250443,153.395462 77.669334,155.396210 81.088226,157.396957 
	C81.321983,157.745453 81.555748,158.093948 81.935081,158.979111 
	C82.153664,159.673218 82.226677,159.830658 82.299690,159.988098 
z"/>
<path fill="#6AB38C" opacity="1.000000" stroke="none" 
	d="
M97.316223,120.901535 
	C93.100929,121.421509 94.480797,118.222374 94.337852,116.232628 
	C94.085968,112.726532 95.468697,108.596199 90.361412,106.631622 
	C95.640709,106.631622 100.920006,106.631622 105.053719,106.631622 
	C104.226730,109.431923 103.323624,112.215332 102.597855,115.044235 
	C102.115067,116.926079 101.885513,118.872902 100.803757,120.870384 
	C99.148666,120.933929 98.232445,120.917732 97.316223,120.901535 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M115.878792,125.886429 
	C115.893196,122.054253 116.194595,118.193886 115.822029,114.399712 
	C115.569893,111.831955 114.369652,109.357307 113.509094,106.567444 
	C117.507591,106.567444 122.737022,106.567444 129.486282,106.567444 
	C123.696716,108.836960 123.933800,112.737114 124.030396,116.716675 
	C124.167824,122.378502 124.158722,128.048096 124.036278,133.710571 
	C123.951378,137.636795 123.813599,141.440781 128.500839,143.522400 
	C123.132820,143.522400 117.764801,143.522400 112.396782,143.522400 
	C117.671989,140.695068 115.554703,135.903534 116.000534,131.430313 
	C116.065132,130.962082 116.142525,130.916061 116.394150,131.243103 
	C116.763985,133.705261 116.556015,135.929764 117.108459,137.945908 
	C117.515900,139.432816 118.677711,141.449936 119.922478,141.819565 
	C122.649780,142.629410 123.034218,140.465027 123.021843,138.223969 
	C122.973366,129.450516 122.983437,120.676598 123.014046,111.902992 
	C123.021515,109.761749 122.977371,107.966125 119.999588,107.966034 
	C117.019524,107.965942 117.058655,109.771698 116.969421,111.903206 
	C116.771599,116.628204 116.406029,121.346184 116.050301,126.023209 
	C115.991737,125.979393 115.878792,125.886429 115.878792,125.886429 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M96.004349,160.149139 
	C94.896645,159.797073 93.788933,159.444992 92.250183,158.859283 
	C91.819145,158.625626 91.645088,158.198059 92.022728,158.108093 
	C92.969704,157.248993 93.539032,156.479858 94.108368,155.710739 
	C104.018288,157.822205 113.947662,159.061996 123.875023,155.905670 
	C126.376945,155.110184 128.769806,153.971664 131.638474,153.280334 
	C133.056961,154.051575 134.049774,154.533295 135.042587,155.015015 
	C134.200394,155.595581 133.358215,156.176147 131.961853,156.918060 
	C130.946808,157.392242 130.485947,157.705093 130.025070,158.017944 
	C130.025070,158.017944 130.001129,157.998520 129.640945,158.016937 
	C127.515266,158.675262 125.749779,159.315170 123.984283,159.955078 
	C123.547920,159.996140 123.111557,160.037201 122.070908,160.119476 
	C120.964989,160.405762 120.463341,160.650803 119.961700,160.895844 
	C119.213242,160.932083 118.464783,160.968323 116.889137,161.021729 
	C111.710663,161.099350 107.359383,161.159805 103.008102,161.220276 
	C101.945076,161.125168 100.882057,161.030045 99.189766,160.712463 
	C97.708450,160.376389 96.856400,160.262772 96.004349,160.149139 
z"/>
<path fill="#66B38A" opacity="1.000000" stroke="none" 
	d="
M93.856277,155.474976 
	C93.539032,156.479858 92.969704,157.248993 91.743301,158.086700 
	C90.713829,158.170898 90.341415,158.186478 89.969002,158.202072 
	C89.209282,157.821945 88.449562,157.441818 87.266617,156.810974 
	C86.634331,156.269058 86.356369,156.168655 86.009521,156.259033 
	C85.241417,155.795624 84.473320,155.332199 83.206093,154.491364 
	C79.134598,151.771759 75.562225,149.429550 71.989861,147.087357 
	C74.500832,144.365280 78.572250,142.455048 76.886719,137.128113 
	C77.041176,135.904694 77.247002,135.129547 77.955933,134.008759 
	C78.590782,133.288239 78.722527,132.913345 78.854263,132.538467 
	C79.058922,132.090103 79.263588,131.641724 80.052338,131.121490 
	C81.528107,131.406281 82.419785,131.762939 83.311470,132.119583 
	C82.645248,133.237701 81.979027,134.355820 80.738762,135.829285 
	C78.642525,139.598389 77.481369,142.986786 80.858421,146.056274 
	C80.858421,146.056274 80.984825,146.116501 81.005417,146.276184 
	C81.026009,146.435867 81.233574,146.786957 81.233574,146.786957 
	C85.357117,149.604370 89.480659,152.421799 93.856277,155.474976 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M71.597885,147.113739 
	C75.562225,149.429550 79.134598,151.771759 82.890533,154.372253 
	C82.691467,155.419708 82.308830,156.208847 81.507210,157.197479 
	C77.669334,155.396210 74.250443,153.395462 71.578102,151.831604 
	C70.402763,153.466476 69.202126,155.136536 68.001495,156.806595 
	C66.681923,155.772873 64.126381,154.485062 64.276772,153.753525 
	C64.710960,151.641556 65.995697,149.547256 67.439659,147.870880 
	C68.094841,147.110260 69.914505,147.352707 71.597885,147.113739 
z"/>
<path fill="#65B388" opacity="1.000000" stroke="none" 
	d="
M135.322495,154.963654 
	C134.049774,154.533295 133.056961,154.051575 132.002457,153.209320 
	C134.830215,150.064880 138.918030,147.795898 135.810211,141.641129 
	C140.333405,143.284531 144.117081,144.659241 147.972763,146.771835 
	C148.030212,147.997299 148.015656,148.484894 148.001099,148.972473 
	C147.665634,149.061493 147.330154,149.150497 146.373764,149.172256 
	C145.178635,149.440247 144.604431,149.775467 144.030243,150.110718 
	C142.344131,151.077972 140.658020,152.045242 138.369904,153.104080 
	C137.514420,153.451996 137.260986,153.708328 137.007538,153.964661 
	C136.539154,154.280533 136.070786,154.596420 135.322495,154.963654 
z"/>
<path fill="#8DB89F" opacity="1.000000" stroke="none" 
	d="
M174.189880,176.137466 
	C174.635498,174.284897 175.081116,172.432312 175.615997,170.208618 
	C177.409927,170.103973 179.382919,169.988907 182.167603,169.826477 
	C180.376404,170.759644 179.327164,171.306259 178.277924,171.852875 
	C179.022247,172.793930 179.766586,173.735001 180.438629,174.584671 
	C177.081055,174.080185 179.754517,183.233902 173.586533,178.626221 
	C173.637543,177.573547 173.913712,176.855499 174.189880,176.137466 
z"/>
<path fill="#8BB89E" opacity="1.000000" stroke="none" 
	d="
M42.072495,169.074036 
	C42.265347,172.630783 42.416767,175.768417 42.603817,179.644257 
	C41.154388,179.196259 39.455143,178.969528 39.428402,178.616196 
	C39.185356,175.404861 39.200867,172.170242 39.317432,168.948822 
	C39.322151,168.818298 41.086529,168.751434 42.072495,169.074036 
z"/>
<path fill="#8DBAA1" opacity="1.000000" stroke="none" 
	d="
M79.992172,178.174225 
	C80.663361,178.657913 81.038322,178.965576 81.446030,179.300095 
	C77.991142,179.300095 74.597443,179.300095 71.203735,179.300095 
	C71.220085,179.075272 71.236435,178.850433 71.252785,178.625610 
	C72.482773,178.806915 73.712753,178.988235 75.557182,179.260132 
	C75.789383,176.180862 76.014664,173.193237 76.239952,170.205627 
	C76.781036,170.082352 77.322128,169.959076 77.863213,169.835785 
	C78.474121,172.556595 79.085030,175.277390 79.992172,178.174225 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M107.324997,127.038254 
	C107.005882,126.138100 107.015350,125.381241 107.041756,124.245789 
	C109.753616,124.540352 112.448540,125.213493 115.511124,125.886536 
	C115.878792,125.886429 115.991737,125.979393 116.014740,126.426170 
	C116.072662,128.220657 116.107597,129.568359 116.142532,130.916061 
	C116.142525,130.916061 116.065132,130.962082 116.026451,130.985138 
	C113.209702,129.732651 110.431641,128.457108 107.324997,127.038254 
z"/>
<path fill="#86B49C" opacity="1.000000" stroke="none" 
	d="
M128.936707,176.561829 
	C128.966797,177.455826 128.966705,177.913315 128.989487,178.715210 
	C128.244324,179.081573 127.476280,179.103546 126.341782,178.613708 
	C125.865631,175.081558 125.755959,172.061218 125.646278,169.040878 
	C127.820274,168.562927 129.994278,168.084976 132.168274,167.607025 
	C132.235992,168.033386 132.303726,168.459747 132.371445,168.886108 
	C131.825943,168.949173 131.280441,169.012253 130.106598,169.119919 
	C129.306107,169.443817 129.133942,169.723129 128.961777,170.002441 
	C128.943359,172.043411 128.924927,174.084366 128.936707,176.561829 
z"/>
<path fill="#8AB49D" opacity="1.000000" stroke="none" 
	d="
M116.922699,177.594971 
	C115.956146,178.011246 114.254349,178.328094 114.142593,178.036194 
	C113.180923,175.524307 112.506294,172.902557 111.538322,169.606430 
	C115.287109,171.146545 117.129761,173.382645 116.922699,177.594971 
z"/>
<path fill="#8DB8A0" opacity="1.000000" stroke="none" 
	d="
M98.530235,178.960632 
	C95.353226,177.780609 95.020332,173.505692 98.807007,169.988785 
	C98.807007,172.885315 98.807007,175.781845 98.530235,178.960632 
z"/>
<path fill="#DBF7E8" opacity="1.000000" stroke="none" 
	d="
M167.001633,177.001083 
	C165.611603,177.337051 164.221558,177.673019 162.388458,177.595306 
	C161.987396,175.443451 162.029404,173.705276 162.071411,171.967087 
	C163.442413,171.984299 164.813385,172.001511 166.607407,172.428558 
	C167.020844,174.225937 167.011246,175.613510 167.001633,177.001083 
z"/>
<path fill="#8EB8A0" opacity="1.000000" stroke="none" 
	d="
M162.011261,171.612976 
	C162.029404,173.705276 161.987396,175.443451 162.023651,177.610657 
	C160.178741,179.821701 159.130966,179.417862 158.934998,176.414795 
	C158.948441,174.327515 159.001846,172.612823 159.063873,170.470764 
	C160.032028,170.448578 160.991562,170.853714 162.011261,171.612976 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M103.081100,140.496750 
	C99.580070,136.795776 100.812195,132.191544 102.140457,127.334946 
	C102.575096,131.393829 102.789589,135.716568 103.081100,140.496750 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M103.229675,161.528351 
	C107.359383,161.159805 111.710663,161.099350 116.521667,161.096741 
	C112.578827,163.515060 108.080070,163.966339 103.229675,161.528351 
z"/>
<path fill="#BEE5D1" opacity="1.000000" stroke="none" 
	d="
M167.011932,177.246674 
	C167.011246,175.613510 167.020844,174.225937 167.018768,172.422501 
	C167.559174,171.500565 168.111237,170.994476 168.572311,170.913239 
	C168.405472,171.998947 168.329605,172.659821 168.253754,173.320694 
	C168.498047,173.390335 168.742340,173.459976 168.986633,173.529617 
	C169.346924,172.708832 169.707230,171.888046 170.067505,171.067261 
	C173.034576,170.708191 173.031555,172.216614 171.698273,174.658051 
	C170.216751,176.020630 169.141068,177.031464 168.065384,178.042282 
	C167.717667,177.858948 167.369965,177.675613 167.011932,177.246674 
z"/>
<path fill="#DBFEEE" opacity="1.000000" stroke="none" 
	d="
M168.371323,178.007202 
	C169.141068,177.031464 170.216751,176.020630 171.661957,175.003174 
	C172.564468,175.291702 173.097458,175.586838 173.910172,176.009720 
	C173.913712,176.855499 173.637543,177.573547 173.228760,178.554443 
	C172.412842,178.746246 171.720016,178.723663 171.048721,178.591568 
	C170.248581,178.434113 169.466919,178.182785 168.371323,178.007202 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M80.854439,145.662811 
	C77.481369,142.986786 78.642525,139.598389 80.545128,136.092346 
	C80.900505,139.089844 80.875481,142.179596 80.854439,145.662811 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M97.368759,121.301132 
	C98.232445,120.917732 99.148666,120.933929 100.407906,120.933258 
	C100.710068,121.403175 100.669220,121.889954 100.803307,122.709282 
	C98.857903,123.254822 96.737556,123.467819 94.337624,123.443703 
	C95.179123,122.704628 96.300209,122.202675 97.368759,121.301132 
z"/>
<path fill="#E0F8EC" opacity="1.000000" stroke="none" 
	d="
M74.241104,161.237747 
	C76.401375,160.926605 78.824356,160.906509 81.624039,160.966614 
	C79.501770,161.207489 77.002792,161.368134 74.241104,161.237747 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M124.195419,160.212158 
	C125.749779,159.315170 127.515266,158.675262 129.647705,158.030334 
	C128.145279,158.839966 126.275909,159.654587 124.195419,160.212158 
z"/>
<path fill="#DBF7E8" opacity="1.000000" stroke="none" 
	d="
M125.444588,169.370956 
	C125.755959,172.061218 125.865631,175.081558 125.961517,178.557343 
	C125.712784,175.908875 125.477837,172.804947 125.444588,169.370956 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M83.575638,131.999557 
	C82.419785,131.762939 81.528107,131.406281 80.278152,131.007523 
	C80.744957,130.025742 81.570038,129.086075 82.692001,128.034805 
	C83.554451,128.625916 84.120018,129.328613 85.055290,130.108337 
	C84.896599,130.750092 84.368202,131.314804 83.575638,131.999557 
z"/>
<path fill="#DBFEEE" opacity="1.000000" stroke="none" 
	d="
M169.795029,171.042419 
	C169.707230,171.888046 169.346924,172.708832 168.986633,173.529617 
	C168.742340,173.459976 168.498047,173.390335 168.253754,173.320694 
	C168.329605,172.659821 168.405472,171.998947 168.728424,171.170410 
	C169.157852,171.007690 169.340195,171.012634 169.795029,171.042419 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M96.149734,160.405655 
	C96.856400,160.262772 97.708450,160.376389 98.789169,160.723663 
	C98.110260,160.858948 97.202690,160.760559 96.149734,160.405655 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M120.108253,161.134003 
	C120.463341,160.650803 120.964989,160.405762 121.754341,160.210617 
	C121.446297,160.631058 120.850555,161.001602 120.108253,161.134003 
z"/>
<path fill="#E0F8EC" opacity="1.000000" stroke="none" 
	d="
M65.003708,160.461426 
	C65.508148,160.316467 66.059982,160.446915 66.805428,160.795837 
	C66.349724,160.921829 65.700417,160.829330 65.003708,160.461426 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M90.013161,158.474701 
	C90.341415,158.186478 90.713829,158.170898 91.365662,158.176682 
	C91.645088,158.198059 91.819145,158.625626 91.901199,158.841431 
	C91.341278,158.953918 90.699295,158.850616 90.013161,158.474701 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M148.354141,149.008545 
	C148.015656,148.484894 148.030212,147.997299 148.074219,147.144791 
	C148.702469,147.163773 149.301270,147.547684 149.900085,147.931580 
	C149.502441,148.302597 149.104813,148.673615 148.354141,149.008545 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M90.672150,123.963936 
	C90.447517,124.290627 89.894287,124.604485 89.001007,124.888458 
	C89.221832,124.556084 89.782692,124.253593 90.672150,123.963936 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M130.313080,158.006683 
	C130.485947,157.705093 130.946808,157.392242 131.700684,157.092163 
	C131.529495,157.401749 131.065292,157.698578 130.313080,158.006683 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M144.366196,150.083359 
	C144.604431,149.775467 145.178635,149.440247 146.114120,149.143036 
	C145.884323,149.472702 145.293243,149.764359 144.366196,150.083359 
z"/>
<path fill="#E0F8EC" opacity="1.000000" stroke="none" 
	d="
M82.419388,159.868851 
	C82.226677,159.830658 82.153664,159.673218 82.085632,159.255569 
	C82.240097,159.246780 82.389587,159.498184 82.419388,159.868851 
z"/>
<path fill="#DBF7E8" opacity="1.000000" stroke="none" 
	d="
M129.176086,169.915161 
	C129.133942,169.723129 129.306107,169.443817 129.741180,169.157166 
	C129.862228,169.432587 129.657654,169.658615 129.176086,169.915161 
z"/>
<path fill="#E6FBF1" opacity="1.000000" stroke="none" 
	d="
M86.135086,156.440277 
	C86.356369,156.168655 86.634331,156.269058 86.947472,156.770798 
	C86.743935,156.958008 86.480309,156.838058 86.135086,156.440277 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M137.278656,153.919632 
	C137.260986,153.708328 137.514420,153.451996 138.043396,153.154144 
	C138.062531,153.366608 137.806152,153.620605 137.278656,153.919632 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M85.699303,126.022537 
	C85.751030,126.259354 85.501518,126.550613 84.972321,126.940712 
	C84.927773,126.718697 85.162918,126.397827 85.699303,126.022537 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M90.286057,127.853607 
	C90.271019,127.633408 90.522049,127.403931 91.033066,127.139664 
	C91.046082,127.351364 90.799095,127.597855 90.286057,127.853607 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M81.273590,146.668213 
	C81.233574,146.786957 81.026009,146.435867 80.981758,146.342072 
	C81.130096,146.265274 81.255463,146.365662 81.273590,146.668213 
z"/>
<path fill="#73B290" opacity="1.000000" stroke="none" 
	d="
M78.612900,132.714600 
	C78.722527,132.913345 78.590782,133.288239 78.201080,133.823303 
	C78.085930,133.619247 78.228722,133.254974 78.612900,132.714600 
z"/>
<path fill="#5CB585" opacity="1.000000" stroke="none" 
	d="
M116.394150,131.243103 
	C116.107597,129.568359 116.072662,128.220657 116.073303,126.469986 
	C116.406029,121.346184 116.771599,116.628204 116.969421,111.903206 
	C117.058655,109.771698 117.019524,107.965942 119.999588,107.966034 
	C122.977371,107.966125 123.021515,109.761749 123.014046,111.902992 
	C122.983437,120.676598 122.973366,129.450516 123.021843,138.223969 
	C123.034218,140.465027 122.649780,142.629410 119.922478,141.819565 
	C118.677711,141.449936 117.515900,139.432816 117.108459,137.945908 
	C116.556015,135.929764 116.763985,133.705261 116.394150,131.243103 
z"/>

         
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}